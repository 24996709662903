import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { GoogleMapBox } from "../../components/Map";
import { populate } from "./DailyAQISlice";
import { info } from "../../components/rangeInfo/AQIDangerRanges";
import { MapMarker } from "../../components/Map/mapmarker";
import { changeDevice } from "../../app/InputSlice";
import { getTodaysData } from "../../api/getTodaysData";
import moment from "moment";
import { getTodaysData2 } from "../../api/getTodaysData2";
import Select from "react-select";
import { VscLoading } from "react-icons/vsc";

const DailyAQI: React.FC<{}> = () => {
  const devices = useSelector((state: RootState) => state.devices);
  const daily_data = useSelector((state: RootState) => state.daily);
  const [newData, setNewData] = useState();
  const [loading, setLoading] = useState(false);
  const data = daily_data[0];

  const dispatch = useAppDispatch();

  const { device } = useSelector((root: RootState) => root.input);

  useEffect(() => {
    const callAPI = async () => {
      if (device === null) return;
      try {
        setLoading(true);
        const data = await getTodaysData(device.dev_id, moment());
        const data2 = await getTodaysData2(device.dev_id);
        setNewData(data2.data.data);
        dispatch(populate(data.data.data));
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    callAPI();
  }, [device]);

  const { state } = useSelector((state: RootState) => state.user);
  const [key, setKey] = useState(1);

  const customStyles = {
    singleValue: (provided: any) => ({
      ...provided,
      color: "#ffffff",
    }),

    placeholder: (provided: any) => ({
      ...provided,
      color: "#ffffff",
    }),

    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "#60A5FA",
      color: "#ffffff",
      border: state.isFocused ? 0 : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
    }),
  };

  const [Options, setOptions] = useState<any[]>([]);
  const [district, setDistrict] = useState<any[]>([]);
  const [selectedDistrict, setSelectedDistrict] = useState({
    label: "North 24 Pgs.",
    value: "North 24 Pgs.",
  });

  useEffect(() => {
    const filterdData = devices?.filter(
      (k) => k.district === selectedDistrict?.value
    );

    const deviceOptionArray = filterdData?.map((item) => ({
      value: item.dev_id,
      label: item.location,
    }));

    setOptions(deviceOptionArray);
  }, [selectedDistrict, devices]);

  useEffect(() => {
    const districtOptionArray = devices?.map((item) => ({
      value: item.district,
      label: item.district,
    }));

    setDistrict(
      //@ts-ignore
      [...new Set(districtOptionArray.map((a) => JSON.stringify(a)))].map((a) =>
        JSON.parse(a)
      )
    );
  }, [devices]);

  return (
    <>
      <div className="grid flex-1 m-2 gap-4 overscroll-x-none mainContainer">
        <GoogleMapBox
          options={
            device !== null
              ? {
                  center: {
                    lat: parseFloat(device.lat),
                    lng: parseFloat(device.long),
                  },
                }
              : {}
          }
        >
          {!!devices && devices.length > 0
            ? devices
                .filter((d) => d.lat !== null && d.long !== null)
                .map((d) =>
                  device?.dev_id === d.dev_id ? (
                    <MapMarker
                      lat={parseFloat(d.lat)}
                      lng={parseFloat(d.long)}
                      text={d.location}
                      pm25={parseFloat(d?.pm25)}
                      selected
                      onClick={() => {
                        dispatch(changeDevice(d));
                      }}
                    />
                  ) : (
                    <MapMarker
                      lat={parseFloat(d.lat)}
                      lng={parseFloat(d.long)}
                      text={d.location}
                      pm25={parseFloat(d?.pm25)}
                      onClick={() => {
                        dispatch(changeDevice(d));
                      }}
                    />
                  )
                )
            : null}
        </GoogleMapBox>
        {!!device ? (
          <>
            <div className="flex flex-1 flex-col">
              <div className="flex flex-col md:flex-row  justify-start items-stretch gap-2 w-full bg-blue-400 rounded-md p-1 text-gray-100 font-semibold font-sans text-xl shadow-lg">
                <span className="flex w-full md:w-8/12 flex-row justify-start text-left gap-2 shadow-lg rounded-md bg-transparent m-0 p-0">
                  <div className="my-2 pl-2 text-center text-sm border-r-2 pr-4">
                    District
                  </div>
                  <Select
                    className="basic-single w-full text-black text-sm"
                    styles={customStyles}
                    classNamePrefix="select"
                    isSearchable
                    name="color"
                    // placeholder={district.find(
                    //   (i) => i.value === device?.district
                    // )}
                    value={selectedDistrict}
                    onChange={(e: any) => {
                      setSelectedDistrict(e);
                    }}
                    options={district}
                  />
                </span>
                <span className="flex w-full  flex-row justify-start text-left gap-2 shadow-lg rounded-md bg-transparent m-0 p-0">
                  <div className="my-2 pl-2 text-center text-sm border-r-2 pr-4">
                    Device
                  </div>
                  <Select
                    className="basic-single w-full text-black text-sm"
                    styles={customStyles}
                    classNamePrefix="select"
                    isSearchable
                    name="color"
                    placeholder={
                      Options.find((i) => i.value === device?.dev_id)?.label
                    }
                    value={
                      Options.find((i) => i.value === device?.dev_id)?.label
                    }
                    onChange={(e: any) => {
                      const newDevice = devices.find(
                        (d) => d.dev_id === e.value
                      );
                      if (!!newDevice) dispatch(changeDevice(newDevice));
                    }}
                    options={Options}
                  />
                </span>
              </div>

              {loading ? (
                <div className="flex items-center justify-center">
                  <VscLoading className="animate-spin" size="100px" />
                </div>
              ) : (
                <>
                  <div className="bg-gray-800 p-2 my-2 rounded-md">
                    <div className="flex flex-row justify-start items-center text-gray-200 px-2 py-1 text-sm">
                      <div>Device Location </div>
                      <div className="text-gray-50 text-sm font-bold px-4">
                        {device.location}
                      </div>
                    </div>

                    <div className="flex flex-row flex-wrap justify-between items-center text-gray-200 px-2 text-sm">
                      <div className="flex flex-row">
                        <div className="py-1">Relative Humidity </div>
                        <div className="text-gray-50 text-sm font-bold px-4 py-1">
                          {
                            //@ts-ignore
                            !!newData?.ext_humi ? newData?.ext_humi : "N/A"
                          }{" "}
                          %
                        </div>
                      </div>
                      <div className="flex flex-row">
                        <div className="py-1">Temperature </div>
                        <div className="text-gray-50 text-sm font-bold px-4 py-1">
                          {
                            //@ts-ignore
                            !!newData?.ext_temp ? newData?.ext_temp : "N/A"
                          }{" "}
                          °C
                        </div>
                      </div>
                      {/* <div className="flex flex-row">
                    <div className="py-1">AQI </div>
                    <div className="text-gray-50 text-sm font-bold px-4 py-1">
                      {!!data?.AQI ? data.AQI : "N/A"}
                    </div>
                  </div> */}
                    </div>
                    <div className="flex flex-row flex-wrap justify-between items-start text-gray-200 px-2 text-sm">
                      <div className="flex flex-row">
                        <p className="py-1">Latitude </p>
                        <p className="text-gray-50 text-sm font-bold px-4 py-1">
                          {device.lat}
                        </p>
                      </div>
                      <div className="flex flex-row">
                        <p className="py-1">Longitude </p>
                        <p className="text-gray-50 text-sm font-bold px-4 py-1">
                          {device.long}
                        </p>
                      </div>
                      <div className="flex flex-row">
                        <p className="py-1">Date and Hour </p>
                        <p className="text-gray-50 text-sm font-bold px-4 py-1">
                          {data?.date + ", " + data?.hour} hours
                        </p>
                      </div>
                    </div>
                  </div>

                  {typeof data !== "undefined" ? (
                    <div className="bg-gray-800 py-2 rounded-md">
                      <div className="flex flex-col justify-center items-center ml-1 mr-1">
                        <table className="bg-gray-700 w-full table text-sm tableCSS">
                          <thead className="table-header-group">
                            <tr className="table-row">
                              <th className="py-3 px-1 text-center text-white bg-gray-700">
                                Pollutant
                              </th>
                              <th className="py-3 px-1 text-center text-white bg-gray-700">
                                Average
                              </th>
                              <th className="py-3 px-1 text-center text-white bg-gray-700">
                                Minimum
                              </th>
                              <th className="py-3 px-1 text-center text-white bg-gray-700">
                                Maximum
                              </th>
                            </tr>
                          </thead>
                          <tbody className="table-row-group p-2 text-gray-200">
                            {/* <tr className="py-2 table-row even:bg-gray-600 odd:bg-gray-800">
                          <td className="py-3 px-1 text-center">
                            SO<sub>2</sub>
                          </td>
                          <td className="py-3 px-1 text-center">
                            {data.so2_avg} &#181;g/m<sup>3</sup>
                          </td>
                          <td className="py-3 px-1 text-center">
                            {data.so2_min} &#181;g/m<sup>3</sup>
                          </td>
                          <td className="py-3 px-1 text-center">
                            {data.so2_max} &#181;g/m<sup>3</sup>
                          </td>
                        </tr>
                        <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                          <td className="py-3 px-1 text-center">
                            NO<sub>2</sub>
                          </td>
                          <td className="py-3 px-1 text-center">
                            {data.no2_avg} &#181;g/m<sup>3</sup>
                          </td>
                          <td className="py-3 px-1 text-center">
                            {data.no2_min} &#181;g/m<sup>3</sup>
                          </td>
                          <td className="py-3 px-1 text-center">
                            {data.no2_max} &#181;g/m<sup>3</sup>
                          </td>
                        </tr> */}
                            {/* 
                        <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                          <td className="py-3 px-1 text-center">PM 1</td>
                          <td className="py-3 px-1 text-center">
                            {data.pm1_avg} &#181;g/m<sup>3</sup>
                          </td>
                          <td className="py-3 px-1 text-center">
                            {data.pm1_min} &#181;g/m<sup>3</sup>
                          </td>
                          <td className="py-3 px-1 text-center">
                            {data.pm1_max} &#181;g/m<sup>3</sup>
                          </td>
                        </tr> */}
                            <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                              <td className="py-3 px-1 text-center">PM 2.5</td>
                              <td className="py-3 px-1 text-center">
                                {data.pm25_avg} &#181;g/m<sup>3</sup>
                              </td>
                              <td className="py-3 px-1 text-center">
                                {data.pm25_min} &#181;g/m<sup>3</sup>
                              </td>
                              <td className="py-3 px-1 text-center">
                                {data.pm25_max} &#181;g/m<sup>3</sup>
                              </td>
                            </tr>
                            <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                              <td className="py-3 px-1 text-center">PM 10</td>
                              <td className="py-3 px-1 text-center">
                                {data.pm10_avg} &#181;g/m<sup>3</sup>
                              </td>
                              <td className="py-3 px-1 text-center">
                                {data.pm10_min} &#181;g/m<sup>3</sup>
                              </td>
                              <td className="py-3 px-1 text-center">
                                {data.pm10_max} &#181;g/m<sup>3</sup>
                              </td>
                            </tr>
                            <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                              <td className="py-3 px-1 text-center">
                                Temperature
                              </td>
                              <td className="py-3 px-1 text-center">
                                {data.ext_temp_avg} &#176;C
                              </td>
                              <td className="py-3 px-1 text-center">
                                {data.ext_temp_min} &#176;C
                              </td>
                              <td className="py-3 px-1 text-center">
                                {data.ext_temp_max} &#176;C
                              </td>
                            </tr>
                            <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                              <td className="py-3 px-1 text-center">
                                Humidity
                              </td>
                              <td className="py-3 px-1 text-center">
                                {data.ext_humi_avg} %
                              </td>
                              <td className="py-3 px-1 text-center">
                                {data.ext_humi_min} %
                              </td>
                              <td className="py-3 px-1 text-center">
                                {data.ext_humi_max} %
                              </td>
                            </tr>

                            {!!data?.wind_direction_avg &&
                              data?.wind_direction_avg !== "(due) N" && (
                                <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                                  <td className="py-3 px-1 text-center">
                                    Wind Direction (&#176;)
                                  </td>
                                  <td className="py-3 px-1 text-center">
                                    {data?.wind_direction_avg}
                                  </td>
                                  <td className="py-3 px-1 text-center">-</td>
                                  <td className="py-3 px-1 text-center">-</td>
                                </tr>
                              )}
                            {!!data?.wind_speed_avg && (
                              <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                                <td className="py-3 px-1 text-center">
                                  Wind Speed (m/s)
                                </td>
                                <td className="py-3 px-1 text-center">
                                  {data?.wind_speed_avg.toFixed(2)}
                                </td>
                                <td className="py-3 px-1 text-center">
                                  {data?.wind_speed_min.toFixed(2)}
                                </td>
                                <td className="py-3 px-1 text-center">
                                  {data?.wind_speed_max.toFixed(2)}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <div className="p-4 font-bold font-sans text-gray-800 text-base">
                      No Data Available
                    </div>
                  )}
                </>
              )}

              <div className="right-2 w-full bottom-2 text-xs bg-black opacity-75 text-white rounded-lg disclaimer mt-2">
                <p className="m-3">
                  Disclaimer: West Bengal pollution Control Board has developed
                  a sensor based air pollution Monitoring network. The sensors
                  are periodically calibrated against the reference-grade
                  monitors and are being used for air quality management for the
                  state. The data represent broad trends of air pollution in the
                  locality.These data are being used for the purpose of research
                  only and not to meant for regulatory intent.
                </p>
              </div>
              <div className="flex flex-row justify-left items-center mt-4 overflow-y-auto bg-gray-200 border border-black mb-8">
                <div className="flex flex-grow flex-col bg-gray-800">
                  <h1 className="text-center text-white font-bold text-lg ">
                    24-Hourly NAAQS
                  </h1>
                  <table className="bg-[#f4f6fc] w-full table border-collapse ">
                    <thead className="table-header-group">
                      <tr className="p-2 table-row bg-gray-600 ">
                        <th className="p-1 text-white bg-[##f4f6fc] mx-1 text-center text-sm border border-black border-l-0">
                          PM 2.5 (ug/m3)
                        </th>
                        <th className="p-1 text-white bg-[##f4f6fc] mx-1 text-center text-sm border border-black border-r-0">
                          PM 10 (ug/m3)
                        </th>
                      </tr>
                    </thead>
                    <tbody className="table-row-group p-2 text-gray-200 ">
                      <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800  ">
                        <td className="p-1 text-white bg-[##f4f6fc] mx-1 text-center text-sm border border-black border-l-0">
                          60
                        </td>
                        <td className="p-1 text-white bg-[##f4f6fc] mx-1 text-center text-sm border border-black border-r-0">
                          100
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default DailyAQI;
