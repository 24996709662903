import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export const getTodaysData2 = async (dev_id: string) => {
  const data = {
    dev_id: dev_id,
  };

  const config: AxiosRequestConfig = {
    method: "post",
    url: "https://aqmsdata.wbpcb.gov.in:1443/v1.0/sens/get_latest_data",
    data: data,
  };
  const result = await axios(config).then((e) => e);
  return result;
};
