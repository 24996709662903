import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { RootState } from "../../app/store";

export const getColorFromAQIValue = (aqi: number | undefined) => {
  if (aqi !== undefined && aqi >= 0) {
    switch (true) {
      case aqi === 0:
        return "#efefef";
      case aqi < 50:
        return "#00B050";
      case aqi < 100:
        return "#92D050";
      case aqi < 200:
        return "#FFFF00";
      case aqi < 300:
        return "#FFC000";
      case aqi <= 400:
        return "#FF0000";
      case aqi > 400:
        return "#C00000";
    }
  }
};

export const MapMarker: React.FC<{
  onClick: () => void;
  lat: number;
  lng: number;
  text: string;
  pm25: number;
  selected?: boolean;
}> = ({ text, selected, pm25, onClick }) => {
  // let color = getColorFromAQIValue(aqi);



  return (
    <>
      <div
        className="hover:text-gray-800 text-transparent font-bold hover:shadow-lg text-center cursor-pointer"
        data-tip={text}
        onClick={onClick}
      >
         <span
        className="animate-ping absolute inline-flex h-7 w-7 rounded-full opacity-40"
        style={{ backgroundColor: '#8EBBED' }}
      ></span>
        <div
          className={`flex flex-row justify-center items-center z-10 rounded-full w-10 h-10 text-gray-200 border-2 border-white ${
            !!selected ? "" : ""
          }`}
          style={{ background: '#8EBBED' }}
        >
          <p className="-pt-1 text-gray-900">{pm25}</p>
        </div>
        <ReactTooltip />
      </div>
    </>
  );
};

export const MapMarkerMemoized = React.memo(MapMarker);
