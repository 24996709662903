import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Moment } from "moment";

export interface getTodaysData_Item {
  id: number;
  dev_id: string;
  date: string;
  hour: number;
  so2_min: string;
  so2_avg: string;
  so2_max: string;
  no2_min: string;
  no2_avg: string;
  no2_max: string;
  pm1_min: string;
  pm1_avg: string;
  pm1_max: string;
  pm25_min: string;
  pm25_avg: string;
  pm25_max: string;
  pm10_min: string;
  pm10_avg: string;
  pm10_max: string;
  int_temp_min: string;
  int_temp_avg: string;
  int_temp_max: string;
  int_humi_min: string;
  int_humi_avg: string;
  int_humi_max: string;
  ext_temp_min: string;
  ext_temp_avg: string;
  ext_temp_max: string;
  ext_humi_min: string;
  ext_humi_avg: string;
  ext_humi_max: string;
  pm_temp_min: string;
  pm_temp_avg: string;
  pm_temp_max: string;
  pm_humi_min: string;
  pm_humi_avg: string;
  pm_humi_max: string;
  wind_direction_avg: string;
  wind_speed_avg: number;
  wind_speed_max: number;
  wind_speed_min: number;
  AQI: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface getTodaysData_Returns {
  status: string;
  total_data: number;
  data: getTodaysData_Item[];
}

export const getTodaysData = async (
  dev_id: string,
  datehour: Moment
): Promise<AxiosResponse<getTodaysData_Returns>> => {
  const config: AxiosRequestConfig = {
    method: "get",
    url: `https://aqmsdata.wbpcb.gov.in:1443/v1.0/sens/real/get_24h_avg?dev_id=${dev_id}&date_hour=${datehour.format(
      "YYYY-MM-DD HH"
    )}`,
  };
  const result: AxiosResponse<getTodaysData_Returns> = await axios(config);
  return result;
};
