import { ValueType } from "@syncfusion/ej2-grids";

export const sortComparator = (ref: ValueType, cmp: ValueType) => {
    if (typeof ref !== typeof cmp) return 0;
    else {
        switch (typeof ref) {
            case 'string':
                // @ts-ignore
                return parseFloat(ref) - parseFloat(cmp);
            case 'number':
                // @ts-ignore
                return ref - cmp;
            default:
                return 0;
        }
    }
}
