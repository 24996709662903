import React, { useRef } from "react";
import GoogleMapReact from "google-map-react";
import googleMapReact from "google-map-react";

export const GoogleMapBox: React.FC<{
  options?: Partial<googleMapReact.Props>;
}> = ({ children, options }) => {
  const mapref = useRef<GoogleMapReact | null>(null);

  return (
    <div className="h-auto">
      <GoogleMapReact
        bootstrapURLKeys={{
          // Production key
          key: "AIzaSyC6IjsKHi8RLOzm7oGGS9vw0uYQcs87nYA"
          // dev key
          // key: "AIzaSyAut8wMgiwUlNdnp0BGlpbG4Jc0umurpM0",
        }}
        defaultCenter={{ lat: 22.56, lng: 88.47 }}
        defaultZoom={7}
        zoom={7}
        resetBoundsOnResize={true}
        ref={(r) => (mapref.current = r)}
        style={{
          height: "100%",
        }}
        {...options}
      >
        {children}
      </GoogleMapReact>
    </div>
  );
};

export const GoogleMapBoxMemoized = React.memo(GoogleMapBox);
