import moment, { Moment } from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  DateTimePickerComponent,
  Inject,
} from "@syncfusion/ej2-react-calendars";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { populate } from "./HourlyAQISlice";
import { VscLoading } from "react-icons/all";
import { changeDevice } from "../../app/InputSlice";
import { enableRipple } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  ExcelExport,
  Filter,
  Resize,
  Sort,
  FilterSettingsModel,
  ToolbarItems,
  PdfExport,
  Search,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { getHourAvgHistorical } from "../../api/getHourAvgHistorical";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { addSerialId } from "../../util/addIndex";
import { sortComparator } from "../../util/sortComparator";
import Select from "react-select";
import { wbpcb_base64 } from "../../util/logo_base64";

enableRipple(true);
const HourlyAQI: React.FC<{}> = () => {
  const rowIndexValue = useRef<number>(0);
  const devices = useSelector((state: RootState) => state.devices);
  const data = useSelector((state: RootState) => state.hourly);
  const [StartDateTime, setStartDateTime] = useState<Moment>(
    moment().subtract(1, "day")
  );
  const [EndDateTime, setEndDateTime] = useState<Moment>(moment());
  const dispatch = useAppDispatch();
  const { device } = useSelector((root: RootState) => root.input);
  const [LoadingData, setLoadingData] = useState<boolean>(false);
  const toolbar: ToolbarItems[] = [
    "ExcelExport",
    "PdfExport",
    // "CsvExport",
    "Search",
    "Print",
  ];
  const filterOptions: FilterSettingsModel = {
    type: "Excel",
  };

  const excelHeaderQueryCellInfo = (args: ClickEventArgs) => {
    if (
      (gridref.current?.excelExportModule as any).sheet.images === null ||
      (gridref.current?.excelExportModule as any).sheet.images === undefined
    ) {
      (gridref.current?.excelExportModule as any).sheet.images = [];
    }
    const excelImage: any = {
      image: wbpcb_base64,
      row: 1,
      column: 1,
      lastRow: 2,
      lastColumn: 2,
    };
    (gridref.current?.excelExportModule as any).sheet.images.push(excelImage);
  };

  const toolbarClick = (args: ClickEventArgs | undefined) => {
    if (gridref.current === null || !args) return;
    switch (args.item.text) {
      case "Excel Export":
        rowIndexValue.current = 0;
        gridref.current.excelExport({
          fileName: `${device?.location}-${StartDateTime.format(
            "ll"
          )}-${EndDateTime.format("ll")}.xlsx`,
          header: {
            headerRows: 7,
            rows: [
              {
                cells: [
                  {
                    colSpan: 15,
                    value: "WBPCB",
                    style: {
                      backColor: "#cde6f7",
                      fontColor: "#000000",
                      fontSize: 18,
                      hAlign: "Center",
                      bold: true,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 15,
                    value: "West Bengal Pollution Control Board",
                    style: {
                      backColor: "#cde6f7",
                      fontColor: "#000000",
                      fontSize: 16,
                      hAlign: "Center",
                      bold: true,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 15,
                    value: `Device: ${
                      Options.find((i) => i.value === device?.dev_id)?.label
                    }`,
                    style: {
                      fontSize: 12,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 15,
                    value:
                      "Parameter: AQI |	NO2 AVG (µg/m³) |	SO2 AVG (µg/m³) |	PM 1 AVG (µg/m³)	| PM 2.5 AVG (µg/m³) |	PM 10 AVG (µg/m³) |	REL HUMI (%) |	TEMPERATURE (°C)",
                    style: {
                      fontSize: 12,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 15,
                    value: `From: ${moment(StartDateTime).format(
                      "DD/MM/YYYY hh:mm:ss"
                    )} To: ${moment(EndDateTime).format(
                      "DD/MM/YYYY hh:mm:ss"
                    )}`,
                    style: {
                      fontSize: 12,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 15,
                    value: `Downloaded on ${moment().format(
                      "DD-MM-YYYY hh:mm:ss A"
                    )}`,
                    style: {
                      fontSize: 12,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 15,
                    value: Options.find((i) => i.value === device?.dev_id)
                      ?.label,
                    style: {
                      backColor: "#204FAC",
                      fontColor: "#ffffff",
                      fontSize: 13,
                      hAlign: "Center",
                    },
                  },
                ],
              },
            ],
          },
        });
        break;
      case "PDF Export":
        rowIndexValue.current = 0;
        gridref.current.pdfExport({
          pageSize: "B1",
          fileName: `${device?.location},-${StartDateTime.format(
            "ll"
          )}-${EndDateTime.format("ll")}.pdf`,
          pageOrientation: "Landscape",
          theme: {
            header: {
              bold: true,
              // border: { color: '#64FA50' },
              fontColor: "#000000",
              fontName: "Calibri",
              fontSize: 14,
            },
            record: {
              fontColor: "#000000",
              fontName: "Calibri",
              fontSize: 12,
            },
          },
          header: {
            contents: [
              {
                type: "Image",
                src: wbpcb_base64,
                position: { x: 0, y: 0 },
                size: { height: 100, width: 400 },
              },
              {
                position: { x: 0, y: 100 },
                style: { textBrushColor: "#000000", fontSize: 25 },
                type: "Text",
                value: "CONTINUOUS AIR QUALITY MONITORING SYSTEM",
              },
              {
                position: { x: 0, y: 135 },
                style: { textBrushColor: "#000000", fontSize: 18 },
                type: "Text",
                value: `Created at: ${moment().format("DD-MM-YYYY HH:mm:ss")}`,
              },
              {
                position: { x: 300, y: 135 },
                style: { textBrushColor: "#000000", fontSize: 18 },
                type: "Text",
                value: `From ${moment(StartDateTime).format(
                  "DD-MM-YYYY"
                )} To ${moment(EndDateTime).format("DD-MM-YYYY")}`,
              },
              {
                position: { x: 0, y: 165 },
                style: { textBrushColor: "#000000", fontSize: 18 },
                type: "Text",
                value: `${
                  Options.find((i) => i.value === device?.dev_id)?.label
                }`,
              },
            ],
            fromTop: 0,
            height: 200,
          },
        });
        break;
      case "CSV Export":
        rowIndexValue.current = 0;
        gridref.current.csvExport({
          fileName: `${device?.location},-${StartDateTime.format(
            "ll"
          )}-${EndDateTime.format("ll")}.csv`,
        });
        break;
    }
  };
  useEffect(() => {
    const callAPI = async () => {
      if (device === null) return;

      const data = await getHourAvgHistorical(
        device.id,
        StartDateTime,
        EndDateTime
      );
      dispatch(populate(addSerialId(data.data.data)));
    };
    callAPI();
  }, []);

  const gridref = useRef<GridComponent | null>(null);
  const { state } = useSelector((state: RootState) => state.user);

  const [gridData, setgridData] = useState<any[]>([]);
  useEffect(() => {
    setgridData([]);
    data.map((item) =>
      setgridData((d) => [
        ...d,
        {
          ...item,
          aqi: device?.aqi,
          location: device?.location,
          district: device?.district,
          lat: device?.lat,
          long: device?.long,
        },
      ])
    );
  }, [data]);

  const [Options, setOptions] = useState<any[]>([]);
  const [district, setDistrict] = useState<any[]>([]);
  const [selectedDistrict, setSelectedDistrict] = useState({
    label: "North 24 Pgs.",
    value: "North 24 Pgs.",
  });

  useEffect(() => {
    const filterdData = devices?.filter(
      (k) => k.district === selectedDistrict?.value
    );

    const deviceOptionArray = filterdData?.map((item) => ({
      value: item.dev_id,
      label: item.location,
    }));

    setOptions(deviceOptionArray);
  }, [devices, selectedDistrict]);

  useEffect(() => {
    const districtOptionArray = devices?.map((item) => ({
      value: item.district,
      label: item.district,
    }));

    setDistrict(
      //@ts-ignore
      [...new Set(districtOptionArray.map((a) => JSON.stringify(a)))].map((a) =>
        JSON.parse(a)
      )
    );
  }, [devices]);

  const customStyles = {
    singleValue: (provided: any) => ({
      ...provided,
      color: "#ffffff",
    }),

    placeholder: (provided: any) => ({
      ...provided,
      color: "#ffffff",
    }),

    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "#60A5FA",
      color: "#ffffff",
      border: state.isFocused ? 0 : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
    }),
  };

  return (
    <>
      <div className="flex flex-col md:flex-row gap-4 justify-start items-center max-w-full bg-blue-400 rounded-md p-4 m-2 text-gray-100 font-semibold font-sans text-sm shadow-lg">
        <span className="flex w-full md:w-3/12 flex-row justify-start items-stretch text-left gap-4 shadow-lg rounded-md bg-transparent m-0 p-3">
          <div className="my-auto pl-2 text-center border-r-2 pr-4">
            District
          </div>
          <Select
            className="basic-single w-full text-black"
            styles={customStyles}
            classNamePrefix="select"
            isSearchable
            name="color"
            value={selectedDistrict}
            onChange={(e: any) => {
              setSelectedDistrict(e);
            }}
            options={district}
          />
        </span>

        <span className="flex w-full md:w-5/12  flex-row justify-start items-stretch text-left gap-4 shadow-lg rounded-md bg-transparent m-0 p-3">
          <div className="my-auto pl-2 text-center border-r-2 pr-4">Device</div>
          <Select
            className="basic-single w-full text-black"
            styles={customStyles}
            classNamePrefix="select"
            defaultValue={device?.dev_id}
            isSearchable
            name="color"
            placeholder={Options.find((i) => i.value === device?.dev_id)?.label}
            onChange={(e: any) => {
              const newDevice = devices.find((d) => d.dev_id === e.value);
              if (!!newDevice) dispatch(changeDevice(newDevice));
            }}
            options={Options}
          />
        </span>
        <span className="flex w-full md:w-3/12  flex-row justify-start items-stretch text-left gap-4 shadow-lg rounded-md bg-transparent m-0 p-3">
          <div className="m-auto pl-2 text-center">Start DateTime</div>
          <DateTimePickerComponent
            id="startdatetimepicker"
            placeholder="Select a start date and time"
            value={StartDateTime.toDate()}
            change={(e) => {
              setStartDateTime(moment(e.value));
            }}
            openOnFocus={true}
          />
        </span>
        <span className="flex  w-full md:w-3/12 flex-row justify-start items-stretch text-left gap-4 shadow-lg rounded-md bg-transparent m-0 p-3">
          <div className="m-auto pl-2 text-center">End DateTime</div>
          <DateTimePickerComponent
            id="enddatetimepicker"
            placeholder="Select an end date and time"
            value={EndDateTime.toDate()}
            change={(e) => {
              setEndDateTime(moment(e.value));
            }}
          />
        </span>
        <span
          className="flex w-full md:w-1/12  flex-row justify-start items-stretch text-left gap-4 shadow-lg rounded-md bg-gray-200 text-blue-600 m-0 p-3 px-8 cursor-pointer"
          onClick={async () => {
            setLoadingData(true);
            if (device === null) return;
            console.log("calling api");
            const data = await getHourAvgHistorical(
              device.id,
              StartDateTime,
              EndDateTime
            );
            dispatch(populate(addSerialId(data.data.data)));
            setLoadingData(false);
            if (gridref.current !== null) gridref.current.refresh();
            console.log("dispatching data");
          }}
        >
          <div className="m-auto mx-auto text-center">
            {LoadingData ? (
              <VscLoading className="animate-spin" size="30px" />
            ) : (
              "Find"
            )}
          </div>
        </span>
      </div>
      <div
        style={{ height: "55vh", width: "99%" }}
        className="mx-2 w-screen overflow-y-scroll bg-white"
      >
        <GridComponent
          dataSource={gridData}
          ref={(r) => (gridref.current = r)}
          toolbar={toolbar}
          toolbarClick={toolbarClick}
          enableStickyHeader
          allowExcelExport
          allowFiltering
          allowSorting
          allowPdfExport
          allowResizing
          enableAltRow
          enableHover
          filterSettings={filterOptions}
          excelHeaderQueryCellInfo={excelHeaderQueryCellInfo}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="serial"
              headerText="Serial"
              allowFiltering={false}
              width="100px"
            />
            <ColumnDirective headerText="Location" field="location" />
            <ColumnDirective headerText="District" field="district" />
            <ColumnDirective field="date" headerText="Date" />
            <ColumnDirective field="hour" headerText="Hour" />
            <ColumnDirective headerText="Latitude" field="lat" />

            <ColumnDirective headerText="Longitude" field="long" />
            <ColumnDirective
              sortComparer={sortComparator}
              field="AQI"
              headerText={"AQI"}
            />
            {/* <ColumnDirective
              sortComparer={sortComparator}
              field="no2_avg"
              headerText={"NO2 AVG (µg/m³)"}
            />
            <ColumnDirective
              sortComparer={sortComparator}
              field="so2_avg"
              headerText={"SO2 AVG (µg/m³)"}
            /> */}
            {/* <ColumnDirective
              sortComparer={sortComparator}
              field="pm1_avg"
              headerText={"PM 1 AVG (µg/m³)"}
            /> */}
            <ColumnDirective
              sortComparer={sortComparator}
              field="pm25_avg"
              headerText={"PM 2.5 AVG (µg/m³)"}
            />
            <ColumnDirective
              sortComparer={sortComparator}
              field="pm10_avg"
              headerText={"PM 10 AVG (µg/m³)"}
            />
            <ColumnDirective
              sortComparer={sortComparator}
              field="ext_humi_avg"
              headerText={"REL HUMI (%)"}
            />
            <ColumnDirective
              sortComparer={sortComparator}
              field="ext_temp_avg"
              headerText={"TEMPERATURE (°C)"}
            />

            <ColumnDirective
              sortComparer={sortComparator}
              field="wind_direction_avg"
              headerText={"Wind Direction Avg (°)"}
            />
            <ColumnDirective
              sortComparer={sortComparator}
              field="wind_speed_avg"
              headerText={"Wind Speed Avg"}
            />
            <ColumnDirective
              sortComparer={sortComparator}
              field="wind_speed_min"
              headerText={"Wind Speed Minimum (in m/s)"}
            />
            <ColumnDirective
              sortComparer={sortComparator}
              field="wind_speed_max"
              headerText={"Wind Speed Maximum (in m/s)"}
            />
          </ColumnsDirective>
          <Inject
            services={[
              Sort,
              Filter,
              Resize,
              ExcelExport,
              PdfExport,
              Toolbar,
              Search,
            ]}
          />
        </GridComponent>
      </div>
      <div className="right-2 w-auto bottom-2 text-xs bg-black opacity-75 text-white rounded-lg disclaimer mt-2 mx-2">
        <p className="m-3">
          Disclaimer: West Bengal pollution Control Board has developed a sensor
          based air pollution Monitoring network. The sensors are periodically
          calibrated against the reference-grade monitors and are being used for
          air quality management for the state. The data represent broad trends
          of air pollution in the locality.These data are being used for the
          purpose of research only and not to meant for regulatory intent.
        </p>
      </div>
    </>
  );
};

export default HourlyAQI;
