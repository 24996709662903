import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { populate } from "./app/DeviceSlice";
import { useAppDispatch } from "./app/hooks";
import { changeDevice } from "./app/InputSlice";
import { RootState } from "./app/store";
import Header from "./components/Header";
import DailyAQI from "./pages/daily";
import HourlyAQI from "./pages/hourly";
import HistoricAQI from "./pages/history";
import { getDeviceList } from "./api/getDeviceList";

function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const loadDevices = async () => {
      const devicesData = await getDeviceList();
      const devices = devicesData.data.data;
      dispatch(populate(devices));
      dispatch(changeDevice(devices[devices.length - 1]));
    };
    loadDevices();
  }, []);

  return (
    <Router>
      <div
        className="flex flex-col justify-start items-stretch min-h-screen"
        style={{
          backgroundImage:
            "linear-gradient(118deg, rgba(231,230,255,1) 0%, rgba(235,255,168,1) 35%, rgba(255,200,110,1) 72%, rgba(255,242,220,1) 100%)",
        }}
      >
        <Header />
        <div className="flex flex-1 justify-start flex-col items-stretch">
          <Switch>
            <Route path="/daily" exact component={DailyAQI} />
            <Route path="/hourly" exact component={HourlyAQI} />
            <Route path="/history" exact component={HistoricAQI} />
            <Route path="/">
              <DailyAQI />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
